<template>
  <div class="campaign-banner" :style="style">
    <component :is="link ? 'a' : 'span'" :[attributeName]="link" :target="target">
      <picture>
        <source media="(min-width:896px)" :srcset="pcImage">
        <source media="(max-width:895px)" :srcset="changeTopImageForSP">
        <img class="campaign-image" :src="changeTopImageForSP" :alt="alt">
      </picture>
    </component>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  file: {
    type: Object,
    default: () => ({})
  },
  alt: {
    type: String,
    default: null
  },
  link: {
    type: String,
    default: null
  },
  backgroundColor: {
    type: String,
    default: null
  },
  target: {
    type: String,
    default: null
  }
})

const attributeName = ref(null)

if (props.link) {
  attributeName.value = 'href'
}

const style = computed(() => {
  return `background-color: ${props.backgroundColor};`
})

const pcImage = computed(() => {
  return VueConfig.cdn_url + props.file.pc
})

const changeTopImageForSP = computed(() => {
  if (!props.file.mobiletop) return VueConfig.cdn_url + props.file.mobile

  // index かそれ以外かを判定する
  const pathname = location.pathname
  if (pathname === "/") {
    return VueConfig.cdn_url + props.file.mobiletop
  }
  return VueConfig.cdn_url + props.file.mobile
})
</script>

<style lang="scss" scoped>
.campaign-banner {
  a {
    display: block;
  }

  span {
    display: block;
  }

  @media screen and (min-width: $breakpoint-md) {
    margin: 0 auto;
    .campaign-image {
      width: 700px;
      height: auto;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    width: 100vw;
  }
}
</style>
