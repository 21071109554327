<template>
  <v-sheet>

    <timer-display v-if="!isHiddenNotification" start="2024-12-03T00:00:00+09:00" end="2025-02-13T23:59:59+09:00"
                  @isDisplay="addMarginTop">
      <div id="notification-bar" class="notification-bar">
        <div class="notification-bar-inner" @click="openLink()">
          <p>大雪に伴う影響について</p>
        </div>
      </div>
    </timer-display>

  </v-sheet>

</template>

<script>
import VbModal from "../atoms/vb-modal.vue";
import ModalHeader from "../atoms/modal-header.vue";
import TimerDisplay from "../atoms/timer-display.vue";

export default {
  name: 'notification-bar',
  components: {
    VbModal,
    ModalHeader,
    TimerDisplay
  },
  data() {
    return {
      cdnUrl: VueConfig.cdn_url,
      notificationModalState: {
        active: false
      },
      ignoreNotificationList: VueConfig.ignoreNotificationList,
      isHiddenNotification: false,
    }
  },
  created() {
    // this.isHiddenNotification = true;
    // if (navigator.cookieEnabled) {
    //   this.resetFirstViewedExpire();
    // }
    if (this.ignoreNotificationList.indexOf(location.pathname) !== -1) {
      this.isHiddenNotification = true;
    }
    // if(this.ignoreNotificationList.indexOf(location.pathname) !== -1) {
    //     this.isHiddenNotification = true;
    // } else {
    //     if(navigator.cookieEnabled) {
    //         // 初回表示の場合モーダルをオープン
    //         if(!this.getFirstViewed()) {
    //             this.notificationModalState.active = true;
    //             this.setFirstViewed();
    //         }
    //         // モーダルをオープン判定の有効期限が切れた場合フラグを削除
    //         if(!this.compareFirstViewedExpire()) {
    //             this.resetFirstViewedExpire();
    //         }
    //     }
    // }
  },
  methods: {
    open: function () {
      this.notificationModalState.active = true;
    },
    close: function () {
      this.notificationModalState.active = false;
    },
    openLink() {
      window.open("https://cloud.msg.value-books.jp/202502Information_deliverymai_CjFcSkyd", "_blank");
    },
    addMarginTop(isDisplay) {
      if (isDisplay) {
        const campaignBanner = document.getElementsByClassName('campaign-banner tile-view')[0];
        if (campaignBanner) {
          campaignBanner.style.marginTop = "40px";
        }
      }
    },
    setFirstViewed() {
      let expire = new Date();
      expire.setDate(expire.getDate() + 14);
      document.cookie = 'notificationModalViewed=true;expires=' + expire + ';';
      document.cookie = 'notificationModalViewed_expires=' + expire + ';expires=' + expire + ';';
    },
    getFirstViewed() {
      let firstViewed = false;
      const cookies = document.cookie.split('; ');
      firstViewed = cookies.find(row => row.startsWith('notificationModalViewed'));
      if (firstViewed) {
        firstViewed = firstViewed.split('=')[1];
      }
      return firstViewed;
    },
    resetFirstViewedExpire() {
      const cookies = document.cookie.split('; ');
      let firstViewed = cookies.find(row => row.startsWith('notificationModalViewed'));
      let firstViewedExpire = cookies.find(row => row.startsWith('notificationModalViewed_expires'));
      if (firstViewed) {
        document.cookie = firstViewed + ';max-age=0;';
      }
      if (firstViewedExpire) {
        document.cookie = firstViewedExpire + ';max-age=0;';
      }
    },
    compareFirstViewedExpire() {
      const now = new Date();
      const cookies = document.cookie.split('; ');
      let firstViewedExpire = cookies.find(row => row.startsWith('notificationModalViewed_expires'));
      if (firstViewedExpire) {
        firstViewedExpire = firstViewedExpire.split('=')[1];
      }
      return (new Date(firstViewedExpire) > now)
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-bar {
  .notification-bar-inner {
    background: #f5f5f5;
    border-bottom: 1px solid $gray_light_color;
    cursor: pointer;
    padding: 10px;

    p {
      text-align: center;
      font-weight: bold;
      word-break: keep-all;
    }

    a {
      width: fit-content;
      margin: 0 auto;
      color: #2c2c2c;
      font-weight: bold;
      background-size: 5px 9px;
      padding-right: 9px;
      line-height: 1.2;
    }
  }

  .delay-message {
    .illust-box {
      max-width: 320px;
      display: block;
      margin: 0 auto 40px;
    }
  }

  ::v-deep {
    .modal-header h3 {
      position: relative;
      margin-top: 0;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      word-break: keep-all;
      padding: 0 24px;
    }

    .modal-body {
      margin: 25px 0 2em;
    }

    .modal-container {
      width: 100%;
      box-sizing: border-box;
      padding: initial;
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
      transition: all .3s ease;
      font-family: Helvetica, Arial, sans-serif;
      height: 100%;
    }

    .modal-enter {
      opacity: 0;
    }

    .modal-leave-active {
      opacity: 0;
    }

    .modal-enter .modal-container,
    .modal-leave-active .modal-container {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  .notification-modal-inner {
    background-color: #FFF;
    height: 90vh;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    ol {
      li {
        list-style: decimal;
        list-style-position: inside;
      }
    }

    .icon-close {
      width: 20px;
      margin: 24px 24px 0 0;
      float: right;
      cursor: pointer;
    }

    .modal-header {
      clear: both;
      padding-top: 20px;
    }

    .modal-body {
      max-height: 100%;
      padding: 0 24px;
      overflow-y: unset;
    }

    .btn-close {
      height: 32px;
      border-radius: 4px;
      border: solid 1px #2c2c2c;
      margin: 24px auto;
      padding: 0;
      cursor: pointer;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @supports (-webkit-touch-callout: none) {
    .ios-bottom-spaces {
      margin-bottom: 160px;
      padding-bottom: 40px;
    }
  }
  @media screen and (min-width: $breakpoint-md) {
    ::v-deep .modal-container {
      max-width: 720px;
      margin: 20px auto;
    }
    .notification-modal-inner {
      width: 100%;
      height: auto;
      margin: 20px auto;

      .modal-body {
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .btn-close {
        width: 320px;
        margin: 32px auto;
      }
    }
    ::v-deep {
      .modal-header h3 {
        font-size: 20px;
      }

      .modal-container {
        width: unset;
        height: unset;
      }
    }
  }
}
</style>
